<template>
  <div id="base-plan" class="mb-10" style="width: 100% !important">
    <div>
      <div class="row">
        <div class="col-md-12" style="background: white">
          <h5 class="mt-4">Visitor Info</h5>
          <hr />
          <div class="row mt-4">
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Date</label>
                <date-picker
                  :min-date="date"
                  :date-format="'YYYY-MM-DD'"
                  :shortcuts="dateoptions"
                  valueType="format"
                  v-model="date"
                  :class="[errors['date'] ? 'border border-danger' : '']"
                ></date-picker>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Name</label>
                <input
                  ref="name"
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="name"
                  :class="errors['name'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['name']" class="text-danger">{{
                  errors["name"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Contact Number</label>
                <input
                  type="number"
                  name=""
                  class="form-control"
                  id=""
                  v-model="contact_no"
                  :class="errors['contact_no'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['contact_no']" class="text-danger">{{
                  errors["contact_no"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Address</label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="address"
                  :class="errors['address'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['address']" class="text-danger">{{
                  errors["address"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">In Time</label>
                <input
                  type="time"
                  name=""
                  class="form-control"
                  id=""
                  v-model="in_time"
                  :class="errors['in_time'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['in_time']" class="text-danger">{{
                  errors["in_time"][0]
                }}</span>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="">Out Time</label>
                <input
                  type="time"
                  name=""
                  class="form-control"
                  id=""
                  v-model="out_time"
                  :class="errors['out_time'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['out_time']" class="text-danger">{{
                  errors["out_time"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">No. of Visitor</label>
                <input
                  type="number"
                  name=""
                  class="form-control"
                  id=""
                  v-model="no_of_visitor"
                  :class="errors['no_of_visitor'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['no_of_visitor']" class="text-danger">{{
                  errors["no_of_visitor"][0]
                }}</span>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="">Visit To</label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="for_whom"
                  :class="errors['for_whom'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['for_whom']" class="text-danger">{{
                  errors["for_whom"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Reason</label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="visit_reason"
                  :class="errors['visit_reason'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['visit_reason']" class="text-danger">{{
                  errors["visit_reason"][0]
                }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="">Card Number</label>
                <input
                  type="text"
                  name=""
                  class="form-control"
                  id=""
                  v-model="card_number"
                  :class="errors['card_number'] ? 'border border-danger' : ''"
                />
                <span v-if="errors['card_number']" class="text-danger">{{
                  errors["card_number"][0]
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn btn-smplan-fixed">
        <button class="btn btn-warning mt-2 mb-2 mr-5" @click="goback()">
          Go back
        </button>
        <button
          v-if="!isEdit"
          ref="kt_save_service"
          class="btn btn-success mt-2 mb-2 mr-3"
          @click="store('S', 'kt_save_service')"
        >
          Save
        </button>
        <button
          v-if="!isEdit"
          class="btn btn-success mt-2 mb-2 mr-3"
          @click="store('SAA', 'kt_save_and_add_service')"
          ref="kt_save_and_add_service"
        >
          <i class="fa fa-check"></i>Save and Add Another
        </button>
        <button
          v-if="isEdit"
          ref="kt_submit_base_plan"
          class="btn btn-success mt-2 mb-2 mr-3"
          @click="update()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2/dist/sweetalert2.js";

import {
  STORE_VISITOR_LOG,
  FETCH_VISITOR,
} from "@/core/services/store/actions.type";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      dateoptions: [{ text: "Today", onClick: () => new Date() }],
      isEdit: false,
      id: 0,

      errors: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Add Visitor",
          route: "",
        },
      ],
      name: "",
      in_time: this.$moment().format("HH:mm"),
      out_time: "",
      contact_no: "",
      no_of_visitor: 1,
      address: "",
      for_whom: "",
      visit_reason: "",
      card_number: "",
      date: this.$moment(new Date()).format("YYYY-MM-DD"),
      add_status: 1,
      error_status: 1,
    };
  },
  created() {
    this.isEdit = this.$route.name == "client.visitorlogs.edit" ? true : false;
    var id = this.$route.params.id;
    if (this.isEdit) {
      this.breadCrumbs[1].title = "Update Visitor";
      this.$store
        .dispatch(FETCH_VISITOR, id)
        .then((data) => {
          this.name = data.data.name;
          this.in_time = data.data.in_time;
          this.out_time = data.data.out_time;
          this.contact_no = data.data.contact_no;
          this.no_of_visitor = data.data.no_of_visitor;
          this.address = data.data.address;
          this.for_whom = data.data.for_whom;
          this.visit_reason = data.data.visit_reason;
          this.card_number = data.data.card_number;
          this.date = data.data.date;
        })
        .catch(() => {
          this.$toastr.e("detail not found!");
          this.$router.push({ name: "client.visitorlog" });
        });
    } else {
      this.focusInput();
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    focusInput() {
      setTimeout(() => {
        this.$refs.name.focus();
      }, 1);
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    store(saveType, ref) {
      this.loadingButton(ref);
      var formData = {
        id: this.$route.params.id,
        name: this.name,
        in_time: this.in_time,
        out_time: this.out_time,
        contact_no: this.contact_no,
        no_of_visitor: this.no_of_visitor,
        address: this.address,
        for_whom: this.for_whom,
        visit_reason: this.visit_reason,
        card_number: this.card_number,
        date: this.date,
      };
      this.$store
        .dispatch(STORE_VISITOR_LOG, formData)
        .then((res) => {
          this.errors = [];
          this.closeBtnLoad(ref);
          this.$toastr.s(res.msg);

          //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
          if (saveType == "S") {
            this.$router.push({
              name: "client.visitorlogs",
            });
          } else if (saveType == "SAA") {
            this.clearForm();
          }
        })
        .catch((res) => {
          this.errors = res.msg;
          this.closeBtnLoad(ref);
          //   this.$toastr.e("Something went wrong !");
        });
    },

    update() {
      var formData = {
        id: this.$route.params.id,
        name: this.name,
        in_time: this.in_time,
        out_time: this.out_time,
        contact_no: this.contact_no,
        no_of_visitor: this.no_of_visitor,
        address: this.address,
        for_whom: this.for_whom,
        visit_reason: this.visit_reason,
        card_number: this.card_number,
        date: this.date,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      this.$store
        .dispatch(STORE_VISITOR_LOG, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.visitorlogs",
          });
        })
        .catch((res) => {
          this.errors = res.msg;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          //   this.$toastr.e("Something went wrong !");
        });
    },
    clearForm() {
      this.name = "";
      this.in_time = "";
      this.out_time = "";
      this.contact_no = "";
      this.no_of_visitor = "";
      this.address = "";
      this.for_whom = "";
      this.visit_reason = "";
      this.card_number = "";
    },
    removeplan(index) {
      this.details.splice(index, 1);
    },
    planpush() {
      let plan = {
        variety: "",
        flour: "",
        corn_flour: "",
        veg_fat: "",
        sugar: "",
        invert_syrup: "",
        malt_ext: "",
        salt: "",
        gms: "",
        lecithin: "",
        ammonia: "",
        soda: "",
        sms: "",
        yeast: "",
        papain: "",
        cocokita_oil: "",
        soya: "",
        vitamin: "",
        cocoa_powder: "",
        coconut_powder: "",
        bran: "",
        dough_serap: "",
        biscuit_powder: "",
      };
      this.details.push(plan);
      this.add_status = 1;
    },
    reset() {
      this.plan = {
        variety: "",
        flour: "",
        corn_flour: "",
        veg_fat: "",
        sugar: "",
        invert_syrup: "",
        malt_ext: "",
        salt: "",
        gms: "",
        lecithin: "",
        ammonia: "",
        soda: "",
        sms: "",
        yeast: "",
        papain: "",
        cocokita_oil: "",
        soya: "",
        vitamin: "",
        cocoa_powder: "",
        coconut_powder: "",
        bran: "",
        dough_serap: "",
        biscuit_powder: "",
      };
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.row_red {
  background-color: #f89494 !important;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.5rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.35;
  border-radius: 0.42rem;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.table thead th,
.table thead td {
  font-size: 12px !important;
}
.btn-smplan-fixed {
  position: fixed;
  top: 93%;
  background-color: #ffff;
  width: calc(71vw - 0%);

  button {
    float: right;
  }
}
table {
  td {
    padding: 0.25rem;
    vertical-align: top;
    border-top: 1px solid #ebedf3;
  }
  .baseplan-header {
    background: #b4cfe2;
  }
}
</style>
<style>
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 10%;
}
.ino {
  width: 4%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
td,
th {
  margin: 0;
  white-space: nowrap;
  border-top-width: 0px;
}
.resp {
  overflow-x: scroll;
  overflow-y: visible;
  padding: 0;
}
.headcol {
  position: absolute;
  width: 5em;
  left: 0;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
  background-color: white;
}
.varietycol {
  position: absolute;
  width: 18%;
  left: 3rem;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
  background-color: white;
  border-right: 1px solid black;
}
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.45rem;
}
.mx-datepicker .mx-input {
  height: 2.8rem;
}
.lastcol {
  position: absolute;
  width: 5em;
  right: 0;
  top: auto;
  border-top-width: 2px;
  margin-top: -1px;
  background-color: white;
  border-left: 1px solid black;
}

.long {
  background: #8cdba3;
  letter-spacing: 1em;
}
</style>
